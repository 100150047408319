import { render, staticRenderFns } from "./fulfillment-picking-queue-details.vue?vue&type=template&id=83a7078e&scoped=true&"
import script from "./js/fulfillment-picking-queue-details.js?vue&type=script&lang=js&"
export * from "./js/fulfillment-picking-queue-details.js?vue&type=script&lang=js&"
import style0 from "./fulfillment-picking-queue-details.vue?vue&type=style&index=0&id=83a7078e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "83a7078e",
  null
  
)

export default component.exports